import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import topbarLogo from '../../../assets/patcom-logo-edu.png';

import css from './TopbarDesktop.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAward,
  faChalkboardUser,
  faCirclePlus,
  faDollarSign,
  faEnvelope,
  faFileCirclePlus,
  faGear,
  faGift,
  faLayerGroup,
  faMoneyBill1,
  faMoneyCheckDollar,
  faPersonChalkboard,
  faPersonCircleCheck,
  faPowerOff,
  faTrophy,
  faUser,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const { profileCompleted, userType, approvalStatus } =
    currentUser?.attributes?.profile?.protectedData || {};

  const totalPoints =
    currentUser?.attributes?.profile?.privateData?.rewardPoint?.rewardPointsTotal || 0;

  const classes = classNames(rootClassName || css.root, className);

  const isAllowedToSee = userType === 'trainer' && profileCompleted;

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  // const inboxLink =
  //   profileCompleted && authenticatedOnClientSide ? (
  //     <NamedLink
  //       className={css.inboxLink}
  //       name="InboxPage"
  //       params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
  //     >
  //       <span className={css.inbox}>
  //         <FormattedMessage id="TopbarDesktop.inbox" />
  //         {notificationDot}
  //       </span>
  //     </NamedLink>
  //   ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      {isAllowedToSee ? (
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="NewListingPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
              name="NewListingPage"
              disabled={approvalStatus === 'Approved' ? false : true}
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faFileCirclePlus} />
              <FormattedMessage id="TopbarDesktop.createListing" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="InboxPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: 'sales' }}
              disabled={approvalStatus === 'Approved' ? false : true}
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faEnvelope} />
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </NamedLink>
          </MenuItem>
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
              disabled={approvalStatus === 'Approved' ? false : true}
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faLayerGroup} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faUser} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faGear} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faPowerOff} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      ) : userType === 'trainee' ? (
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="InboxPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: 'orders' }}
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faEnvelope} />
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faUser} />

              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="MyPointsPage">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('MyPointsPage'))}
              name="MyPointsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faDollarSign} />
              <FormattedMessage id="TopbarDesktop.myPointsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faGear} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faPowerOff} />

              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      ) : userType === 'admin' ? (
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="UserApprovalPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('UserApprovalPage'))}
              name="UserApprovalPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faChalkboardUser} />
              Trainer approval
            </NamedLink>
          </MenuItem>
          <MenuItem key="TraineeApprovalPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('TraineeApprovalPage'))}
              name="TraineeApprovalPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faUserCheck} />
              Trainee approval
            </NamedLink>
          </MenuItem>
          <MenuItem key="CategoryManagementPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('CategoryManagementPage')
              )}
              name="CategoryManagementPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faMoneyBill1} />
              Category Price Policy
            </NamedLink>
          </MenuItem>
          <MenuItem key="RewardPointsConfigPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('RewardPointsConfigPage')
              )}
              name="RewardPointsConfigPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faTrophy} />
              Rewards Config
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faUser} />

              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faGear} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faPowerOff} />

              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      ) : (
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faUser} />

              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faGear} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FontAwesomeIcon icon={faPowerOff} />

              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      )}
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
  const aboutUsLink = (
    <NamedLink name="AboutPage" className={css.loginLink}>
      <span className={css.createListing}>About Us</span>
    </NamedLink>
  );
  const contactUsLink = (
    <NamedLink name="ContactUsPage" className={css.loginLink}>
      <span className={css.createListing}>Contact Us</span>
    </NamedLink>
  );
  const searchPageLink = (
    <NamedLink name="SearchPage" className={css.loginLink}>
      <span className={css.createListing}>Search Training</span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoContainer} name="LandingPage">
        <img className={css.topbarLogo} src={topbarLogo} alt={'Patcom'} />
      </NamedLink>
      {currentPage === 'SearchPage' ? search : null}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {searchPageLink}
        {userType === 'trainee' && (
          <NamedLink name="MyPointsPage">
            <div className={css.loginLink}>
              <span className={css.createListing}>{totalPoints} Points</span>
            </div>
          </NamedLink>
        )}
        {userType === 'trainer' && (
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        )}

        {/* {inboxLink} */}
        {profileMenu}
        {signupLink}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
