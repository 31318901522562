import React from 'react';
import PropTypes from 'prop-types';

const IconLanguages = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.11429 19L4.57069 15.1038C4.57117 15.0997 4.57053 15.0956 4.56883 15.0918L3.17284 11.9934C1.57509 8.44715 3.78735 4.3525 7.62918 3.74516V3.74516C10.3781 3.31061 13.0607 4.86739 14.0593 7.46507V7.46507C14.1666 7.74416 14.2177 8.0428 14.209 8.34167L14.1718 9.61969C14.1716 9.6292 14.1735 9.63863 14.1776 9.64724L15.5257 12.5243C15.7465 12.9956 15.4026 13.5366 14.8821 13.5366V13.5366C14.4896 13.5366 14.1714 13.8548 14.1714 14.2473V15.9364C14.1714 16.5307 13.6562 16.9938 13.0653 16.9308L11.4261 16.7558C10.9103 16.7008 10.438 17.0494 10.3386 17.5586L10.0571 19"
        stroke="#222222"
       strokeLinecap="round"
      ></path>
      <path d="M21.6818 9L18 11.5455" stroke="#222222"strokeLinecap="round"></path>
      <path d="M21.6818 19L18 16.4545" stroke="#222222"strokeLinecap="round"></path>
      <path d="M22.5 14H18" stroke="#222222"strokeLinecap="round"></path>
    </svg>
  );
};

IconLanguages.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconLanguages.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconLanguages;
