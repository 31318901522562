import React from 'react';
import PropTypes, { bool } from 'prop-types';

// External link that opens in a new tab/window, ensuring that the
// opened page doesn't have access to the current page.
//
// See: https://mathiasbynens.github.io/rel-noopener/
const ExternalLink = props => {
  const { children, targetSelf, ...rest } = props;
  return (
    <a {...rest} target={targetSelf ? '_self' : '_blank'} rel="noopener noreferrer">
      {children}
    </a>
  );
};

ExternalLink.defaultProps = { children: null, targetSelf: false };

const { node } = PropTypes;

ExternalLink.propTypes = { children: node, targetSelf: bool };

export default ExternalLink;
