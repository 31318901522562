import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { approveOrRejectUser, fetchUsers } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const GET_USERS_REQUEST = 'app/UserApprovalPage/GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'app/UserApprovalPage/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'app/UserApprovalPage/GET_USERS_ERROR';

export const SET_PAGINATION_META = 'app/UserApprovalPage/SET_PAGINATION_META';

// ================ Reducer ================ //
const mockData = [
  {
    id: { uuid: '3c073fae-6172-4e75-8b92-f560d58cd47c' },
    type: 'user',
    attributes: {
      banned: false,
      deleted: false,
      createdAt: new Date('2018-03-28T09:12:58.866Z'),
      email: 'joe.dunphy@example.com',
      emailVerified: true,
      pendingEmail: null,
      stripeConnected: false,
      profile: {
        firstName: 'Joe',
        lastName: 'Dunphy',
        displayName: 'Joe D',
        abbreviatedName: 'JD',
        bio: "Hello, I'm Joe.",
        publicData: {
          age: 27,
        },
        protectedData: {
          phoneNumber: '+1-202-555-0177',
        },
        privateData: {
          newsletter: true,
        },
      },
    },
  },
];

const initialState = {
  users: [],
  loading: true,
  pagination: {
    total: 0,
    perPage: 8,
    page: 1,
  },
};

export default function userApprovalReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_USERS_REQUEST:
      return { ...state, loading: true };
    case GET_USERS_SUCCESS:
      return { ...state, users: payload, loading: false };
    case GET_USERS_ERROR:
      return { ...state, users: [], loading: false };

    case SET_PAGINATION_META:
      return { ...state, pagination: { ...state.pagination, ...action.payload } };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getUserRequest = e => ({
  type: GET_USERS_REQUEST,
  payload: { e },
});

export const getUserSuccess = e => ({
  type: GET_USERS_SUCCESS,
  payload: e,
});

export const getUserError = e => ({
  type: GET_USERS_ERROR,
  error: true,
  payload: e,
});

export const setPaginationMeta = payload => ({
  type: SET_PAGINATION_META,
  payload,
});

// ================ Thunks ================ //
export const showUsers = payload => (dispatch, getState, sdk) => {
  const paginationState = getState().UserApprovalPage.pagination;
  const { perPage, page } = paginationState;

  const { nextPage, filterText, status } = payload;
  dispatch(getUserRequest(payload));
  return fetchUsers({ userType: 'trainer', perPage, page: nextPage || 1, filterText, status })
    .then(response => {
      dispatch(setPaginationMeta(response.meta));
      dispatch(getUserSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(getUserError(storableError(e))));
};

export const approvalUser = e => (dispatch, getState, sdk) => {
  const paginationState = getState().UserApprovalPage.pagination;
  const { page } = paginationState;

  dispatch(getUserRequest(e));
  return approveOrRejectUser({ userId: e.userId, approvalStatus: 'Approved' })
    // .then(response => {
    //   return dispatch(showUsers({ nextPage: page }));
    // })
    .catch(e => dispatch(getUserError(storableError(e))));
};

export const rejectUser = e => (dispatch, getState, sdk) => {
  const paginationState = getState().UserApprovalPage.pagination;
  const { page } = paginationState;

  dispatch(getUserRequest(e));
  return approveOrRejectUser({ userId: e.userId, approvalStatus: 'Suspended' })
    // .then(() => {
    //   return dispatch(showUsers({ nextPage: page }));
    // })
    .catch(e => dispatch(getUserError(storableError(e))));
};

export const setPaginationSettings = perPage => dispatch => {
  return dispatch(setPaginationMeta({ perPage, page: 1 }));
};
