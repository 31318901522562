import { LOCATION_CHANGED } from '../ducks/routing.duck';
import { CONFIRM_SUCCESS, SIGNUP_SUCCESS } from '../ducks/auth.duck';

// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(canonicalPath, previousPath): called when the URL is changed
// - trackUserRegisterEvent(): called when a user registered successfully
export const createMiddleware = handlers => store => next => action => {
  const { type, payload } = action;

  if (type === LOCATION_CHANGED) {
    const previousPath = store?.getState()?.routing?.currentCanonicalPath;
    const { canonicalPath } = payload;
    handlers.forEach(handler => {
      handler.trackPageView(canonicalPath, previousPath);
    });
  } else if (type === CONFIRM_SUCCESS || type === SIGNUP_SUCCESS) {
    handlers.forEach(handler => {
      handler.trackUserRegisterEvent();
    });
  }

  next(action);
};
