import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';

const UserNav = props => {
  const { className, rootClassName, currentPage, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const { profileCompleted, userType, approvalStatus } =
    currentUser?.attributes?.profile?.protectedData || {};

  const isAllowedToSee =
    userType === 'trainer' && profileCompleted && approvalStatus === 'Approved';

  const trainerTabs = [
    isAllowedToSee
      ? {
          text: <FormattedMessage id="TopbarDesktop.inbox" />,
          selected: currentPage === 'InboxPage',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'sales' },
          },
          disabled: !isAllowedToSee,
        }
      : null,
    isAllowedToSee
      ? {
          text: <FormattedMessage id="UserNav.yourListings" />,
          selected: currentPage === 'ManageListingsPage',
          linkProps: {
            name: 'ManageListingsPage',
          },
          disabled: !isAllowedToSee,
        }
      : null,

    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  const traineeTabs = [
    {
      text: <FormattedMessage id="TopbarDesktop.inbox" />,
      selected: currentPage === 'InboxPage',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
      disabled: false,
    },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.myPoints" />,
      selected: currentPage === 'MyPointsPage',
      disabled: false,
      linkProps: {
        name: 'MyPointsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  const adminTabs = [
    {
      text: 'Trainer Approval',
      selected: currentPage === 'UserApprovalPage',
      disabled: false,
      linkProps: {
        name: 'UserApprovalPage',
      },
    },
    {
      text: 'Trainee Approval',
      selected: currentPage === 'TraineeApprovalPage',
      disabled: false,
      linkProps: {
        name: 'TraineeApprovalPage',
      },
    },
    {
      text: 'Category Management',
      selected: currentPage === 'CategoryManagementPage',
      disabled: false,
      linkProps: {
        name: 'CategoryManagementPage',
      },
    },
    {
      text: 'Rewards Config',
      selected: currentPage === 'RewardPointsConfigPage',
      disabled: false,
      linkProps: {
        name: 'RewardPointsConfigPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  const trainerProfile = profileCompleted && userType === 'trainer';
  const traineeProfile = profileCompleted && userType === 'trainee';
  const adminProfile = userType === 'admin';

  const tabs = trainerProfile
    ? trainerTabs
    : traineeProfile
    ? traineeTabs
    : adminProfile
    ? adminTabs
    : [];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

const UserNavComponent = compose(connect(mapStateToProps))(UserNav);

export default UserNavComponent;
