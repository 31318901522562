import { fetchSystemParameters } from '../util/api';

export const FETCH_SYSTEM_PARAMETERS_REQUEST =
  'app/systemparameters/FETCH_SYSTEM_PARAMETERS_REQUEST';
export const FETCH_SYSTEM_PARAMETERS_SUCCESS =
  'app/systemparameters/FETCH_SYSTEM_PARAMETERS_SUCCESS';
export const FETCH_SYSTEM_PARAMETERS_ERROR = 'app/systemparameters/FETCH_SYSTEM_PARAMETERS_ERROR';

const initialState = {
  categoryPricePolicy: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SYSTEM_PARAMETERS_SUCCESS:
      return { ...state, ...payload };

    default:
      return state;
  }
}

export const systemParametersRequest = () => ({
  type: FETCH_SYSTEM_PARAMETERS_REQUEST,
});

export const systemParametersRequestSuccess = payload => ({
  type: FETCH_SYSTEM_PARAMETERS_SUCCESS,
  payload,
});

export const fetchSystemParametersPromise = () => dispatch => {
  return fetchSystemParameters()
    .then(response => {
      dispatch(systemParametersRequestSuccess(response.data));
    })
    .catch(e => {
      throw e;
    });
};
