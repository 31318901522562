import React from 'react';
import css from './LinkButton.module.css';

const LinkButton = ({ type = 'blue', tag = 'button', children, ...other }) => {
  const ButtonComponent = tag === 'a' ? 'a' : 'button';

  const className = css.button + ' ' + css[type];

  return (
    <ButtonComponent className={className} {...other}>
      {children}
    </ButtonComponent>
  );
};

export default LinkButton;
