import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { approveOrRejectUser, fetchUsers, setRewardPoints } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const GET_USERS_REQUEST = 'app/TraineeApprovalPage/GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'app/TraineeApprovalPage/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'app/TraineeApprovalPage/GET_USERS_ERROR';

export const SET_PAGINATION_META = 'app/TraineeApprovalPage/SET_PAGINATION_META';

export const SET_REWARD_POINTS_REQUEST = 'app/TraineeApprovalPage/SET_REWARD_POINTS_REQUEST';
export const SET_REWARD_POINTS_REQUEST_SUCCESS =
  'app/TraineeApprovalPage/SET_REWARD_POINTS_REQUEST_SUCCESS';
export const SET_REWARD_POINTS_REQUEST_ERROR =
  'app/TraineeApprovalPage/SET_REWARD_POINTS_REQUEST_ERROR';

// ================ Reducer ================ //

const initialState = {
  users: [],
  loading: true,
  pagination: {
    total: 0,
    perPage: 8,
    page: 1,
  },
  isAssignRewardLoading: false,
  assignRewardRequestError: null,
};

export default function traineeApprovalReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_USERS_REQUEST:
      return { ...state, loading: true };
    case GET_USERS_SUCCESS:
      return { ...state, users: payload, loading: false };
    case GET_USERS_ERROR:
      return { ...state, users: [], loading: false };

    case SET_PAGINATION_META:
      return { ...state, pagination: { ...state.pagination, ...action.payload } };

    case SET_REWARD_POINTS_REQUEST:
      return { ...state, assignRewardRequestError: null, isAssignRewardLoading: true };
    case SET_REWARD_POINTS_REQUEST_SUCCESS:
      return { ...state, isAssignRewardLoading: false, assignRewardRequestError: null };
    case SET_REWARD_POINTS_REQUEST_ERROR:
      return { ...state, isAssignRewardLoading: false, assignRewardRequestError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getUserRequest = e => ({
  type: GET_USERS_REQUEST,
  payload: { e },
});

export const getUserSuccess = e => ({
  type: GET_USERS_SUCCESS,
  payload: e,
});

export const getUserError = e => ({
  type: GET_USERS_ERROR,
  error: true,
  payload: e,
});

export const setPaginationMeta = payload => ({
  type: SET_PAGINATION_META,
  payload,
});

export const setRewardPointsRequest = () => ({
  type: SET_REWARD_POINTS_REQUEST,
});

export const setRewardPointsSuccess = () => ({
  type: SET_REWARD_POINTS_REQUEST_SUCCESS,
});

export const setRewardPointsError = error => ({
  type: SET_REWARD_POINTS_REQUEST_ERROR,
  payload: error,
});

// ================ Thunks ================ //
export const showUsers = payload => (dispatch, getState, sdk) => {
  const paginationState = getState().TraineeApprovalPage.pagination;
  const { perPage, page } = paginationState;
  const { nextPage, filterText, status } = payload;

  dispatch(getUserRequest(payload));

  return fetchUsers({
    userType: 'trainee',
    perPage,
    page: nextPage || page || 1,
    filterText,
    status,
  })
    .then(response => {
      dispatch(setPaginationMeta(response.meta));
      dispatch(getUserSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(getUserError(storableError(e))));
};

export const approvalUser = e => (dispatch, getState, sdk) => {
  const paginationState = getState().TraineeApprovalPage.pagination;
  const { page } = paginationState;

  dispatch(getUserRequest(e));
  return (
    approveOrRejectUser({ userId: e.userId, approvalStatus: 'Approved' })
      // .then(response => {
      //   return dispatch(showUsers({ nextPage: page }));
      // })
      .catch(e => dispatch(getUserError(storableError(e))))
  );
};

export const rejectUser = e => (dispatch, getState, sdk) => {
  const paginationState = getState().TraineeApprovalPage.pagination;
  const { page } = paginationState;

  dispatch(getUserRequest(e));
  return (
    approveOrRejectUser({ userId: e.userId, approvalStatus: 'Suspended' })
      // .then(() => {
      //   return dispatch(showUsers({ nextPage: page }));
      // })
      .catch(e => dispatch(getUserError(storableError(e))))
  );
};

export const setPaginationSettings = perPage => dispatch => {
  return dispatch(setPaginationMeta({ perPage, page: 1 }));
};

/**
 * @param {object} params
 * @param {string} params.id id value of selected row
 * @param {number} params.totalRewardPoints new total reward points to be assigned
 * @returns
 */
export const assignRewardPoints = params => (dispatch, getState) => {
  const paginationState = getState().TraineeApprovalPage.pagination;
  dispatch(setRewardPointsRequest());

  return setRewardPoints(params)
    .then(() => {
      dispatch(showUsers({ ...paginationState }));
      return dispatch(setRewardPointsSuccess());
    })
    .catch(e => dispatch(setRewardPointsError(storableError(e))));
};
