import { fetchCommissionRate } from '../../util/api';

export const FETCH_COMMISSION_ASSET = 'app/RewardPoinstConfigPage/FETCH_COMMISSION_ASSET';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COMMISSION_ASSET:
      return { ...state, ...payload };

    default:
      return state;
  }
}

const fetchCommissionAsset = payload => ({
  type: FETCH_COMMISSION_ASSET,
  payload,
});

export const fetchCommissionAssetRequest = () => dispatch => {
  return fetchCommissionRate().then(res => {
    dispatch(fetchCommissionAsset(res.data));
  });
};
