import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEM_REWARD_POINTS, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemRewardPointsItemsMaybe = props => {
  const { rewardPointSpendMultiplier, lineItems, intl, isCustomer } = props;

  const lineItem = lineItems.find(item => item.code === LINE_ITEM_REWARD_POINTS && !item.reversal);
  const lineTotal = lineItem && lineItem.lineTotal;
  const label =
    lineItem &&
    lineTotal &&
    lineTotal.amount &&
    rewardPointSpendMultiplier &&
    `${humanizeLineItemCode(lineItem.code)} (${(lineTotal.amount /
      Number(rewardPointSpendMultiplier) /
      100) *
      -1} pts)`;

  const formattedTotal = lineItem && formatMoney(intl, lineItem.lineTotal);

  return lineItem && isCustomer ? (
    <React.Fragment>
      <div key={`item.code`} className={css.lineItem}>
        <span className={css.itemLabel}>{label}</span>
        <span className={css.itemValue}>{formattedTotal}</span>
      </div>
    </React.Fragment>
  ) : null;
};

LineItemRewardPointsItemsMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemRewardPointsItemsMaybe;
